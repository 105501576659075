<template>
  <div class="flex items-center justify-center w-full h-2/5">
    <div class="grid grid-cols-3 mx-1 ml-4 gap-5 w-full h-full">
      <div class="grid gap-5 grid-rows-7 col-span-1">
        <!-- New Hire Section -->

        <router-link
          class="row-span-1 bg-lightGrey flex items-center justify-center cursor-pointer rounded-xl hover:shadow-2xl transition-all duration-500 mt-2"
          :to="{ name: 'ma-new-staff' }"
        >
          <div
            @click="removeRequest"
            class="flex py-5 items-center justify-center"
          >
            <span class="material-icons text-5xl text-grey mx-3">
              group_add
            </span>
            <p class="text-grey font-semibold text-lg mx-3">
              Hire New Staff Member
            </p>
          </div>
        </router-link>

        <!-- Permanent/Casual/Fixed Term/Zero-Hour Contracts Section -->
        <div
          class="row-span-1 bg-lightGrey flex items-center justify-center rounded-xl"
        >
          <div class="grid grid-cols-2 grid-rows-2 gap-4" v-if="!isLoading">
            <contract-type-icon
              :classIcon="'text-teal text-7xl cursor-text'"
              :classTitle="'text-lg'"
              :classNotification="'text-teal top-10 cursor-text'"
              :title="'Permanent'"
              :value="getNewApplicationsHiringRequest?.count.permanent"
            />
            <contract-type-icon
              :classIcon="'text-orange text-7xl cursor-text cursor-text'"
              :classTitle="'text-lg'"
              :classNotification="'text-orange top-10'"
              :title="'Casual'"
              :value="getNewApplicationsHiringRequest?.count.casual"
            />
            <contract-type-icon
              :classIcon="'text-lightGreen text-7xl cursor-text cursor-text'"
              :classTitle="'text-lg'"
              :classNotification="'text-lightGreen top-10'"
              :title="'Fixed Term'"
              :value="getNewApplicationsHiringRequest?.count['fixed-term']"
            />
            <contract-type-icon
              :classIcon="'text-red text-7xl cursor-text cursor-text'"
              :classTitle="'text-lg'"
              :classNotification="'text-red top-10'"
              :title="'Zero-Hour'"
              :value="getNewApplicationsHiringRequest?.count['zero-hour']"
            />
          </div>
          <div v-if="isLoading" class="object-center content-center pt-5">
            <heart-rate fast="false" bgColor="background"></heart-rate>
          </div>
        </div>
        <!-- My Interviews Section -->
        <div
          class="row-span-3 mb-3 bg-lightGrey flex rounded-xl items-center justify-center hover:shadow-2xl transition-all duration-500 cursor-pointer"
        >
          <span class="material-icons text-9xl text-grey mx-3">
            interpreter_mode
          </span>
        </div>
      </div>
      <div class="col-span-2 flex-col h-full py-3 p-2">
        <!-- Up-comping Intervies -->
        <div class="bg-teal rounded-xl h-1.9/5 mb-2">
          <h3 class="text-lg text-white text-center pt-2">
            <span v-if="getPracticeInterviews?.data?.length < 1">No</span>
            Upcoming Interviews
          </h3>
          <div>
            <vueper-slides
              v-if="!interviewsAreLoading"
              fixed-height="280px"
              class="no-shadow"
              :visible-slides="3"
              :dragging-distance="20"
              :gap="-2"
              :bullets-outside="false"
              :arrows-outside="false"
            >
              <vueper-slide
                v-for="interview in getPracticeInterviews?.data"
                :key="interview.id"
              >
                <template v-slot:content>
                  <slide :icon="'description'" :title="interview.user.email" />
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
        <!-- Open Vacancies Table Section -->
        <div class="bg-lightGrey rounded-xl my-5">
          <div class="pl-4 column-gap-2 flex">
            <div>
              <h2 class="text-grey font-bold">Open Vacancies</h2>
              <h3 class="text-grey">Department of Administration</h3>
              <div :key="refreshFilter" class="text-teal bold">
                Filters:
                <select
                  @change="onColumnFilter"
                  class="bg-lightGrey py-1 px-3 text-grey"
                  name="Column FIlters"
                >
                  <option
                    v-for="vacancy in vacancyTypes"
                    :key="vacancy.name"
                    :value="vacancy.value"
                    :hidden="vacancy.name === 'Select Column :'"
                  >
                    {{ vacancy.name }}
                  </option>
                </select>
                <select
                  v-if="columnFilter != 'job_title'"
                  @change="onColumnValue"
                  class="bg-lightGrey py-1 px-3 text-grey"
                  name="Column Values"
                >
                  <option
                    v-for="column in columnFieldValues"
                    :key="column.name"
                    :value="column.id ? column.id : column.value"
                    :hidden="column.name === 'Select Value :'"
                  >
                    {{ column.name }}
                  </option>
                </select>
                <input
                  v-else
                  type="text"
                  class="text-grey px-2 mx-2"
                  placeholder=" Enter Title :"
                  v-model="columnValue"
                />
                <div class="flex pl-2 gap-2 mr-2 float-right">
                  <button
                    type="button"
                    class="text-sm bg-teal hover:bg-teal text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    @click="applyFilters"
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    class="text-sm bg-yellow hover:bg-yellow text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    @click="resetFilters"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="float:right pl-40 ml-40">
              <label class="text-teal" for="Location Filter"
                ><b
                  >&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp; Status Filter:
                </b></label
              >
              <select
                @change="onStatusFilter"
                class="bg-lightGrey py-1 px-2 text-grey"
                name="Location Fiter"
              >
                <option :value="'pending'">Pending</option>
                <option :value="'approved'">Approved</option>
                <option :value="'declined'">Declined</option>
                <option :value="'escalated'">Escalated</option>
              </select>
            </div> -->
            <div class="pl-35 ml-40 float-right"></div>
          </div>

          <table
            v-if="
              !isLoading && getNewApplicationsHiringRequest?.data?.length > 0
            "
            class="text-center w-full text-grey my-5"
          >
            <tr class="w-1/3">
              <th class="pb-6 text-lg border-b-2">TYPE</th>
              <th class="pb-6 text-lg border-b-2">JOB TITLE</th>
              <th class="pb-6 text-lg border-b-2">PROGRESS</th>
              <th class="pb-8 text-lg border-b-2">ACTION</th>
            </tr>
            <tr
              class=""
              v-for="(vacancy, index) in getNewApplicationsHiringRequest?.data"
              :key="index"
            >
              <td class="m-1 my-1 border-b-2">
                {{ vacancy.contract_type.toUpperCase() }}
              </td>
              <td class="m-1 my-1 border-b-2 break-all" width="40%">
                {{ vacancy.job_title.toUpperCase() }}
              </td>
              <td class="m-1 my-1 border-b-2 tooltip">
                {{ vacancy.progress.toUpperCase() }}
                <span
                  v-if="
                    columnValue === 'declined' || columnValue === 'escalated'
                  "
                  class="tooltiptext p-4 break-all"
                >
                  <b>Reason:</b> {{ vacancy.decision_reason }}<br /><b
                    >Comment:</b
                  >
                  {{ stripHtmlToText(vacancy.comment) }}</span
                >
              </td>
              <td class="m-1 my-1 border-b-2">
                <el-dropdown>
                  <span class="material-icons"> more_horiz </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <template #default>
                          <div @click="reRoute(vacancy)">View</div>
                        </template>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </td>
            </tr>
          </table>
          <div
            class="pt-2 grid place-items-center"
            v-if="
              !isLoading && getNewApplicationsHiringRequest?.data?.length < 1
            "
          >
            No records found!
          </div>

          <div
            v-if="isLoading"
            class="object-center content-center pt-20 pb-10"
          >
            <heart-rate fast="false" bgColor="background"></heart-rate>
          </div>
          <div :key="refreshFilter" class="pb-2 grid place-items-center">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="getNewApplicationsHiringRequest?.total"
              @current-change="myCallback"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slide from "@/components/ui/baseComponents/slide.vue";
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import contractTypeIcon from "@/components/ui/baseComponents/contractTypeIcon.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide, contractTypeIcon, slide },
  async created() {
    await this.fetchHiringRequests(this.page);
    await this.loadInterviews();
    await this.fetchRoles();
    this.getRoles();
    this.columnFieldValues = this.contractTypes;
  },

  mounted() {
    if (this.$route.query.new) {
      const emptyObject = {};
      this.$store.commit("MAnewHire/setNewHiringRequest", emptyObject);
      this.$store.commit("MAnewHire/setSingleJobSpecification", null);
      this.$store.commit("MAnewHire/setSinglePersonSpecification", null);
      this.$store.commit("profile/setWorkTimings", null);
    } else if (this.$route.query.error) {
      this.$toast.error(`Something went wrong`);
    }
  },
  data() {
    return {
      refreshFilter: 0,
      isLoading: false,
      interviewsAreLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      vacancyTypes: [
        { value: "", name: "Select Column :" },
        { value: "contract_type", name: "Type" },
        { value: "job_title", name: "Job Title" },
        { value: "progress", name: "Progress" },
      ],
      contractTypes: [
        { value: "", name: "Select Value :" },
        { value: "permanent", name: "Permanent" },
        { value: "casual", name: "Casual" },
        { value: "fixed-term", name: "Fixed Term" },
        { value: "zero-hour", name: "Zero Hour" },
      ],
      progressTypes: [
        { value: "pending-approval", name: "Pending Approval" },
        { value: "in-process", name: "In Process" },
        { value: "interviews-in-progress", name: "Interview In Progress" },
        { value: "offer-made", name: "Offer Made" },
        { value: "completed", name: "Completed" },
      ],
      roles: [],
      columnFieldValues: {},
      jobTitleInputFlag: false,
      columnFilter: "",
      columnValue: "",
    };
  },
  methods: {
    async fetchRoles() {
      try {
        await this.$store.dispatch("ReLocums/fetchRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    reRoute(item) {
      this.$router.push({
        name: "ma-view-staff-hiring-request",
        params: { id: item.id },
      });
    },
    async myCallback(e) {
      await this.fetchHiringRequests(e);
    },
    async fetchHiringRequests(page) {
      try {
        this.isLoading = true;
        // Dispatch action with arguments in Array form -> inside action use ES6 argument destructing
        await this.$store.dispatch("MAnewHire/fetchHiringRequests", [
          {
            practice: localStorage.getItem("practice"),
            [`${this.columnFilter}`]: this.columnValue,
          },
          page,
        ]);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchFilteredHiringRequests(page, type, value) {
      try {
        this.isLoading = true;
        // Dispatch action with arguments in Array form -> inside action use ES6 argument destructing
        await this.$store.dispatch("MAnewHire/fetchHiringRequests", [
          {
            practice: localStorage.getItem("practice"),
            [`${type}`]: value,
          },
          page,
        ]);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadInterviews() {
      this.interviewsAreLoading = true;
      try {
        const payload = { practice: localStorage.getItem("practice") };
        await this.$store.dispatch(
          "MAgeneralModule/fetchPracticeInterviews",
          payload
        );
        this.interviewsAreLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    // onStatusFilter(event) {
    //   console.log("status: " + event.target.value);
    //   this.fetchHiringRequests(event.target.value, this.page);
    // },
    onColumnFilter(event) {
      if (event.target.value === "contract_type") {
        this.columnFieldValues = this.contractTypes;
      } else if (event.target.value === "job_title") {
        this.jobTitleInputFlag = true;
      } else if (event.target.value === "progress") {
        this.columnFieldValues = this.progressTypes;
      } else if (event.target.value === "status") {
        this.columnFieldValues = this.statusTypes;
      } else if (event.target.value === "role") {
        this.columnFieldValues = this.roles;
      }
      this.columnValue = "";
      this.columnFilter = event.target.value;
    },
    onColumnValue(event) {
      console.log("Column Value: " + event.target.value);
      this.columnValue = event.target.value;
    },
    getRoles() {
      return this.$store.getters["ReLocums/getRoles"];
    },
    async applyFilters() {
      this.page = 1;
      await this.fetchFilteredHiringRequests(
        this.page,
        this.columnFilter,
        this.columnValue
      );
    },
    async resetFilters() {
      this.page = 1;
      this.columnFilter = "";
      this.columnValue = "";
      this.refreshFilter++;
      await this.fetchHiringRequests(this.page);
    },
    stripHtmlToText(str) {
      if (str === null || str === "") return "Unavaiable";
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    removeRequest() {
      const emptyObject = {};
      this.$store.commit("MAnewHire/setNewHiringRequest", emptyObject);
      this.$store.commit("MAnewHire/setSingleJobSpecification", null);
      this.$store.commit("MAnewHire/setSinglePersonSpecification", null);
    },
  },
  computed: {
    getNewApplicationsHiringRequest() {
      return this.$store.getters["MAnewHire/getNewApplicationsHiringRequest"];
    },
    getPracticeInterviews() {
      return this.$store.getters["MAgeneralModule/getPracticeInterviews"];
    },
  },
};
</script>

<style scoped>
.vueperslide {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
  height: 85%;
}
.vueperslide--active {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  font-size: 12px;
  visibility: hidden;
  width: 150px;
  color: black;
  text-align: center;
  border-radius: 6px;
  background-color: white;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
